import {DateTime} from "luxon";
import {NoteType} from "@/life/notes";
import {ReasonType} from "@/life/logs";

export class Roles {
  LIFE_CONTROL = false;
  LIFE_CONTROL_UNPROMPTED = false;
  PLAYER_VIEW = false;
  PLAYER_VIEW_COP_GEAR = false;
  PLAYER_VIEW_MED_GEAR = false;
  PLAYER_VIEW_CIV_GEAR = false;
  PLAYER_VIEW_SWAT_GEAR = false;
  PLAYER_VIEW_CONQ_GEAR = false;
  PLAYER_VIEW_HEX_ICON_REDEMPTIONS = false;
  PLAYER_VIEW_CRATE_HORN_KEYS = false;
  PLAYER_VIEW_IS_COMP_BANNED = false;
  PLAYER_VIEW_RESTRICTIONS_LEVEL = false;
  PLAYER_VIEW_CASINO_RESTRICTIONS_LEVEL = false;
  PLAYER_VIEW_MESSAGES = false;
  PLAYER_VIEW_OLYMPUS_PLUS_LEVEL = false;
  PLAYER_VIEW_OLYMPUS_PLUS_AIRDROP = false;
  PLAYER_VIEW_OLYMPUS_PLUS_VEHICLEDROP = false;
  PLAYER_VIEW_OLYMPUS_PLUS_SUB_START = false;
  PLAYER_VIEW_GANG_HISTORY = false;
  PLAYER_VIEW_MPID = false;
  PLAYER_VIEW_FOV = false;
  PLAYER_VIEW_STAFF_POINTS = false;
  PLAYER_EDIT = false;
  PLAYER_WIPE = false;
  PLAYER_EDIT_CIV_GEAR = false;
  PLAYER_EDIT_COP_GEAR = false;
  PLAYER_EDIT_MED_GEAR = false;
  PLAYER_EDIT_SWAT_GEAR = false;
  PLAYER_EDIT_CONQ_GEAR = false;
  PLAYER_EDIT_BANK = false;
  PLAYER_EDIT_DEPOSIT_BOX = false;
  PLAYER_EDIT_CASH = false;
  PLAYER_EDIT_WAR_POINTS = false;
  PLAYER_EDIT_SWAT_POINTS = false;
  PLAYER_EDIT_VIGI_POINTS = false;
  PLAYER_EDIT_STAFF_POINTS = false;
  PLAYER_EDIT_ARRESTED = false;
  PLAYER_EDIT_RESTRICTIONS_LEVEL = false;
  PLAYER_EDIT_CASINO_RESTRICTIONS_LEVEL = false;
  PLAYER_EDIT_IS_COMP_BANNED = false;
  PLAYER_EDIT_DONOR_LEVEL = false;
  PLAYER_EDIT_HEX_ICON_REDEMPTIONS = false;
  PLAYER_EDIT_CRATE_HORN_KEYS = false;
  PLAYER_EDIT_VIGI_ARRESTS = false;
  PLAYER_EDIT_CURRENT_TITLE = false;
  PLAYER_EDIT_COP_LEVEL = false;
  PLAYER_EDIT_SWAT_LEVEL = false;
  PLAYER_EDIT_MEDIC_LEVEL = false;
  PLAYER_EDIT_ADMIN_LEVEL = false;
  PLAYER_EDIT_ADMIN_SQUAD = false;
  PLAYER_EDIT_SQUAD_LEVEL = false;
  PLAYER_EDIT_CIV_COUNCIL_LEVEL = false;
  PLAYER_EDIT_DESIGNER_LEVEL = false;
  PLAYER_EDIT_DESIGNER_SQUAD = false;
  PLAYER_EDIT_DEVELOPER_LEVEL = false;
  PLAYER_EDIT_DEVELOPER_SQUAD = false;
  PLAYER_EDIT_WIKI_LEVEL = false;
  PLAYER_EDIT_SUPPORT_LEVEL = false;
  PLAYER_EDIT_COP_TIG_DATE = false;
  PLAYER_EDIT_COP_MVP = false;
  PLAYER_EDIT_COP_DISCIPLINARY = false;
  PLAYER_EDIT_SWAT_TIG_DATE = false;
  PLAYER_EDIT_SWAT_DISCIPLINARY = false;
  PLAYER_EDIT_MEDIC_TIG_DATE = false;
  PLAYER_EDIT_MEDIC_MVP = false;
  PLAYER_EDIT_MEDIC_DISCIPLINARY = false;
  PLAYER_EDIT_CIV_COUNCIL_TIG_DATE = false;
  PLAYER_EDIT_SUPPORT_TIG_DATE = false;
  PLAYER_EDIT_SUPPORT_MVP = false;
  PLAYER_EDIT_SUPPORT_DISCIPLINARY = false;
  PLAYER_EDIT_LEGACY_DONOR_LEVEL = false;
  PLAYER_EDIT_LICENSES = false;
  PLAYER_VIEW_OTHER_BANS = false;
  PLAYER_VIEW_OTHER_BANS_LIMITED = false;
  PLAYER_VIEW_OTHER_BANS_PUBLIC = false;
  PLAYER_VIEW_OTHER_BANS_FULL = false;
  PLAYER_VIEW_TICKETS = false;
  PLAYER_VIEW_TICKETS_OTHER = false;
  PLAYER_VIEW_EXTERNAL_LINK_BATTLEMETRICS = false;
  GANG_VIEW = false;
  GANG_VIEW_OTHER_LEDGER = false;
  GANG_EDIT = false;
  GANG_EDIT_BANK = false;
  GANG_SHED_VIEW_OTHER_SHED = false;
  GANG_SHED_DELETE = false;
  PLAYER_VIEW_GANGMEMBER_INVITED_BY = false;
  VEHICLE_VIEW_OTHER_VEHICLE = false;
  VEHICLE_CREATE = false;
  VEHICLE_DELETE = false;
  VEHICLE_EDIT = false;
  VEHICLE_EDIT_PLAYER_ID = false;
  VEHICLE_EDIT_IS_ALIVE = false;
  VEHICLE_EDIT_IS_ACTIVE = false;
  VEHICLE_EDIT_INSURANCE_LEVEL = false;
  VEHICLE_EDIT_TURBO_LEVEL = false;
  VEHICLE_EDIT_TRUNK_LEVEL = false;
  VEHICLE_EDIT_SECURITY_LEVEL = false;
  VEHICLE_EDIT_IS_ARMED = false;
  HOUSE_VIEW_OTHER_HOUSE = false;
  HOUSE_DELETE = false;
  HOUSE_EDIT = false;
  HOUSE_EDIT_COMP_PHYSICAL = false;
  HOUSE_EDIT_COMP_VIRTUAL = false;
  HOUSE_EDIT_EXPIRES_ON = false;
  DISPUTE_VIEW = false;
  DISPUTE_VIEW_WITHOUT_CODE = false;
  DISPUTE_VIEW_RECENT_LIST = false;
  DISPUTE_VIEW_LIMITED_WITHOUT_CODE = false;
  DISPUTE_REPLY_CREATE = false;
  DISPUTE_REPLY_RECENT_NOTES = false;
  LCLOG_VIEW = false;
  LIFE_LOGS = false;
  LIFE_LOGS_ALL = false;
  LC_NOTE_VIEW = false;
  LC_PERFORMANCE_PLAYTIME = false;
  LC_ONLINE_PLAYERS_VIEW = false;
  LC_NOTE_CREATE = false;
  LC_NOTE_EDIT_SELF = false;
  LC_NOTE_EDIT_ALL = false;
  LC_NOTE_APD = false;
  LC_NOTE_RNR = false;
  LC_NOTE_SUPPORT_TEAM = false;
  LC_NOTE_CIV_COUNCIL = false;
  LC_NOTE_SWAT = false;
  LC_NOTE_ADMIN = false;
  LC_NOTE_DEV = false;
  LC_NOTE_DES = false;
  LC_NOTE_STAFF = false;
  LC_NOTE_CHIEF = false;
  LC_AUDIT_LOG = false;
  LC_AUDIT_LOG_LIMITED_VIEW = false;
  LC_MULTI_COMP_TOOL = false;
  PLAYER_EDIT_WANTED = false;
  WHITELIST_OVERVIEW_COP = false;
  WHITELIST_OVERVIEW_SWAT = false;
  WHITELIST_OVERVIEW_MED = false;
  WHITELIST_OVERVIEW_CIV_COUNCIL = false;
  WHITELIST_OVERVIEW_SUPPORT = false;
  WHITELIST_OVERVIEW_DESIGNER = false;
  WHITELIST_OVERVIEW_DEVELOPER = false;
  WHITELIST_OVERVIEW_ADMIN = false;
  WHITELIST_OVERVIEW_WIKI = false;
  WHITELIST_OVERVIEW = false;
  VIEW_PAGE_WIKI_IP_CHECK = false;
  PLAYER_VIEW_SERVICES = false;
  PLAYER_EDIT_SERVICES_FORUMS = false;
  COMMUNITY_GOALS_ADMIN = false;
  STAFF_SHOP = false;

  PLAYER_SYNC_SERVICES = false;

  SEARCH = false;
  SEARCH_PLAYER = false;
  SEARCH_PLAYER_LIMITED_COP = false;
  SEARCH_PLAYER_LIMITED_MED = false;
  SEARCH_PLAYER_LIMITED_SWAT = false;
  SEARCH_GANG = false;
  SEARCH_USER = false;

  USER_VIEW = false;
  USER_EDIT = false;
  USER_EDIT_ROLES = false;
  USER_EDIT_SYSTEM_NAME = false;

  MISSION_FILE_ADMIN = false;

  constructor(roles: Array<string>) {
    roles.forEach((role: string) => {
      this[role as keyof Roles] = true;
    });
  }

  get SEARCH_PLAYER_OR_LIMITED() {
    return (
      this.SEARCH_PLAYER ||
      this.SEARCH_PLAYER_LIMITED_COP ||
      this.SEARCH_PLAYER_LIMITED_MED ||
      this.SEARCH_PLAYER_LIMITED_SWAT
    );
  }
}

export interface SavedPlayer {
  name: string;
  playerId: string;
  nickname: string;
}

export interface SavedGang {
  name: string;
  gangId: number;
  nickname: string;
}

export interface User {
  id: string;
  name: string;
  profileName: string;
  avatar: string;
  avatarMedium: string;
  avatarFull: string;
  steamId: string;
  savedPlayers: Array<SavedPlayer>;
  savedGangs: Array<SavedGang>;
  olympusPlusLevel: number;
  isSuperuser: boolean;
  roles: Roles;

  gangMember: {
    gangId: number;
    gangName: string;
    rank: number;
  } | null;
}

export interface Notification {
  id: number;
  isDismissible: boolean;
  isDismissed: boolean;
  title: string;
  url: string | null;
  notificationType: string;
  message: string;
  timestamp: DateTime;
}

export interface ItemConfig {
  name: string;
  illegal: boolean;
  weight: number;
  category: string;
  auctionable: boolean;
}

export interface ItemsConfig {
  [key: string]: ItemConfig;
}

export interface VehicleConfig {
  name: string;
  type: string;
  sides: Array<string>;
  armable?: boolean;
  category: string;
  auctionable: boolean;
}

export interface VehiclesConfig {
  [key: string]: VehicleConfig;
}

export interface CurrentError {
  title: string | null;
  message: string | null;
  isDismissible: boolean;
  links: Array<Link>;
}

export interface Link {
  url: string;
  title: string;
}

export interface WantedChargeConfig {
  name: string;
  value: number;
}

export interface GoalConfig {
  description: string;
  side: string;
}

export interface LicenseConfig {
  name: string;
  side: string[];
}

export interface LicensesConfig {
  [key: string]: LicenseConfig;
}

export interface QueryEventConfig {
  [key: string]: {
    keys: string[];
  };
}

export interface LifeConfigs {
  itemsPhysical: ItemsConfig;
  itemsVirtual: ItemsConfig;
  vehicles: VehiclesConfig;
  charges: Array<WantedChargeConfig>;
  goals: {[key: string]: GoalConfig};
  licenses: LicensesConfig;
}

export interface AppState {
  version: string;

  allRoles: Array<string>;
  sideNavCollapsed: boolean;
  user: User | null;
  notifications: Array<Notification>;
  isLC: boolean;
  isDarkMode: boolean;
  backendURL: string;
  timezone: string;
  zone: string;

  noteTypes: NoteType[];
  logReasonTypes: ReasonType[];

  loading: boolean;

  error: CurrentError;

  config: LifeConfigs;

  showChristmasGift: boolean;
}

export interface SelectOption {
  text: string;
  value: string;
}
